function About() {
	return (
		<div className="About">
			<h2>About Me</h2>
			<p>
				Hey there, my name is Brian Nichols and I’ve been in the IT
				field for over 14 years. Now I'm combining my IT experience
				with development. Check out some of my projects below.
			</p>
		</div>
	);
}

export default About;
